import React from "react"
import Div from "@components/elements/div"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"

const scrollingAnim = keyframes`
  from {
    transform: translate3d(-3383px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

export const LogoAnimation = styled.div`
  width: 10107px;
  display: flex;
  gap: 50px;
  animation: ${scrollingAnim} 60s linear infinite;
`

export const LogoCarousel = ({ dark = false, padding = "5rem 0 2rem" }) => {
  const data = useStaticQuery(
    graphql`
      query LogoCarousel {
        images: allDirectusFiles(
          filter: {
            directus_id: {
              in: [
                "dea59791-afc6-4d57-99ba-d358c8372feb"
                "d07e2a27-034e-47cc-93f5-5245bc5255a1"
              ]
            }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)

  return (
    <Div
      maxWidth="900px"
      width="100%"
      padding={padding}
      overflow="hidden"
      style={{
        WebkitMaskImage:
          "linear-gradient(90deg, transparent 0%, #FFF 30%, #FFF 70%, transparent 100%)",
      }}
    >
      <LogoAnimation>
        <Image
          noDimensions
          file={
            dark
              ? images["d07e2a27-034e-47cc-93f5-5245bc5255a1"]
              : images["dea59791-afc6-4d57-99ba-d358c8372feb"]
          }
        />
        <Image
          noDimensions
          file={
            dark
              ? images["d07e2a27-034e-47cc-93f5-5245bc5255a1"]
              : images["dea59791-afc6-4d57-99ba-d358c8372feb"]
          }
        />
        <Image
          noDimensions
          file={
            dark
              ? images["d07e2a27-034e-47cc-93f5-5245bc5255a1"]
              : images["dea59791-afc6-4d57-99ba-d358c8372feb"]
          }
        />
      </LogoAnimation>
    </Div>
  )
}

LogoCarousel.propTypes = {
  dark: PropTypes.bool,
  padding: PropTypes.string,
}
